import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// A modern alternative to CSS resets
import 'normalize.css';
// Global css setting
import './global.css';

import Router from 'layouts/Router';

import {
	loadConfigFromLocalStorage,
	loadGoogleLibrary,
	loadFacebookLibrary,
	loadAppleLibrary,
	extendDayjsPlugin,
} from 'util/external';

import configureStore from './store';
import history from './store/history';
import routes from './routes';

export const store = configureStore({});

loadConfigFromLocalStorage(store);
loadGoogleLibrary(store);
loadFacebookLibrary();
loadAppleLibrary();
extendDayjsPlugin();

ReactDOM.render(
	<Provider store={store}>
		<Router history={history} routes={routes} store={store} />
	</Provider>,
	document.getElementById('content'),
);
